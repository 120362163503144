import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import NumberFormat from "react-number-format"
import { Controller } from "react-hook-form"

import { inputStyle } from "utils"

import Error from "./Error"

// https://codesandbox.io/s/react-hook-form-controller-079xx?file=/src/index.js:833-1061

/**
 * @description Input Component
 * @typedef {{
 * name: String
 * type: String
 * className: String
 * placeholder: String
 * required: Boolean
 * register: Function
 * errors: {}
 *
 * Props
 * @type React.FunctionComponent<Props>
 *
 * @example
 * <Phone name="test" />
 */

const Phone = ({
  name,
  control,
  required,
  errors,
  placeholder,
  label,
  ...other
}) => {
  return (
    <InputWrap>
      <label htmlFor={name}>{label}</label>
      <Controller
        render={({ field: { onChange, value } }) => {
          return (
            <NumberFormat
              format="(###) ###-####"
              // format="###-###-####"
              mask="_"
              onValueChange={(v) => onChange(v.value)}
              value={value}
              placeholder={placeholder}
              name={name}
              {...other}
            />
          )
        }}
        name={name}
        control={control}
        required={required}
        {...other}
      />

      <Error errors={errors} name={name} />
    </InputWrap>
  )
}

const InputWrap = styled.div`
  ${inputStyle};
`

Phone.defaultProps = {
  name: "",
  className: "",
  type: "text",
  placeholder: "placeholder",
  required: false,
}

Phone.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
}

export default Phone
