import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import ReactDatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { Controller } from "react-hook-form"

import Error from "./Error"

// https://codesandbox.io/s/react-hook-form-controller-079xx?file=/src/index.js:833-1061
// https://react-hook-form.com/api

/**
 * @description DatePicker Component
 * @typedef {{
 * name: String
 * control: {name: String}
 * className: String
 * placeholder: String
 * errors: {}
 *
 * Props
 * @type React.FunctionComponent<Props>
 *
 * @example
 * <DatePicker name="test" control={control} />
 */

const DatePicker = ({ name, control, className, placeholder, errors }) => {
  return (
    <StyledDatePicker>
      <Controller
        name={name}
        control={control}
        render={({ onChange, onBlur, value }) => (
          <ReactDatePicker
            className={className ? `${className} datePicker` : "datePicker"}
            onChange={onChange}
            onBlur={onBlur}
            selected={value}
            placeholderText={placeholder}
          />
        )}
      />
      <Error errors={errors} name={name} />
    </StyledDatePicker>
  )
}

const StyledDatePicker = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }
  .datePicker {
    width: 100%;
    font-size: 20px;
    border: 1px solid #e0e0e0;
    width: 100%;
    padding: var(--sp-16);
    border-radius: 3px;
    font-family: var(--primary-font-family);
    font-weight: normal;
    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #5f5f5f;
    }
  }
`

DatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  className: PropTypes.string,
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
}

export default DatePicker
