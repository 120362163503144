import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import Error from "./Error"

import { inputStyle } from "utils"

// https://codesandbox.io/s/react-hook-form-controller-079xx?file=/src/index.js:833-1061

/**
 * @description Input Component
 * @typedef {{
 * name: String
 * type: String
 * className: String
 * placeholder: String
 * required: Boolean
 * register: Function
 * errors: {}
 *
 * Props
 * @type React.FunctionComponent<Props>
 *
 * @example
 * <Input name="test" />
 */

const Input = ({
  name,
  register,
  className,
  type,
  label,
  placeholder,
  required,
  errors,
  ...other
}) => {
  return (
    <InputWrap className={`${name}-wrap`}>
      <label htmlFor={name}>{label}</label>
      <input
        name={name}
        type={type}
        className={className ? `${className} input` : "input"}
        placeholder={placeholder}
        autoComplete="off"
        {...register(name, {
          required,
        })}
        {...other}
      />
      <Error errors={errors} name={name} />
    </InputWrap>
  )
}

const InputWrap = styled.div`
  ${inputStyle};
  label {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 16px;
    margin-bottom: var(--sp-8);
    display: inline-block;
  }
`

Input.defaultProps = {
  name: "",
  className: "",
  type: "text",
  placeholder: "placeholder",
  required: false,
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  className: PropTypes.string,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
}

export default Input
