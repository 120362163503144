import React from "react"
import ReactSelect from "react-select"
import PropTypes from "prop-types"
import { Controller } from "react-hook-form"

import Error from "./Error"
import styled from "styled-components"
import {inputStyle} from "utils"

// https://codesandbox.io/s/react-hook-form-controller-079xx?file=/src/index.js:833-1061

/**
 * @description Select Component
 * @typedef {{
 * name: String
 * control: {name: String}
 * options: [{value: String label: String}]
 * className: String
 * placeholder: String
 * errors: {}
 *
 * Props
 * @type React.FunctionComponent<Props>
 *
 * @example
 * <Select options=[{value: '', label: ''}] control={control} />
 */

const Select = ({ name, control, options, className, placeholder, errors }) => {
  const styles = {
    placeholder: (base) => ({
      ...base,
      color: "#385594",
      fontWeight: "normal",
      fontFamily: "Azeret",
      letterSpacing: "0.01rem",
      margin: 0,
    }),
    indicatorSeparator: (base) => ({
      ...base,
      backgroundColor: "#6a80f2",
      display: "none",
    }),
    menu: (provided, state) => ({
      ...provided,
      // width: state.selectProps.width,
      border: "1px solid #6B8DFF",
      fontFamily: "Azeret",
      background: "#0b182a",
      color: state.selectProps.menuColor,
      padding: 20,
      borderRadius: "1.3em",
      // boxShadow: "0px 8px 48px rgba(0, 0, 0, 0.12)",
    }),
    menuList: (base) => ({
      ...base,
      fontWeight: "normal",
      fontFamily: "Azeret",
      letterSpacing: "0.01rem",
      margin: 0,
    }),
    singleValue: (base) => ({
      ...base,
      color: "white",
    }),
    valueContainer: (base) => ({
      ...base,
      paddingLeft: 0,
      color: "white",
      paddingTop: 0,
      paddingBottom: 0,
    }),
    option: (base, state) => ({
      ...base,
      fontFamily: "Azeret",
      background: state.isSelected ? "#0A152F" : "#0A152F",
      color: state.isSelected ? "white" : "#9BB1DE",
      "&:hover": {
        background: "#11203E",
        color: "white",
      },
    }),
    control: (base) => ({
      ...base,
      fontFamily: "Azeret",
      padding: "10px 24px",
      fontSize: 14,
      color: "#282828",
      background: "#0a152f",
      border: "1px solid #385594",
      borderRadius: "45px",
      outline: "none",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #385594",
        // border: "1px solid #e0e0e0",
      },
    }),
  }

  return (
    <InputWrap className={`${name}-wrap`}>
      <Controller
        isMulti
        render={({ field }) => (
          <ReactSelect
            isClearable
            {...field}
            styles={styles}
            options={options}
            placeholder={placeholder}
          />
        )}
        className={className ? `${className} select` : "select"}
        name={name}
        options={options}
        control={control}
      />
      <Error errors={errors} name={name} />
    </InputWrap>
  )
}

const InputWrap = styled.div`
  ${inputStyle};
`
Select.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
}

export default Select
