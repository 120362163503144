import React, { useState } from "react"
import styled from "styled-components"

import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup" // for everything
import { One, Input } from "components/inputs"

const isProd =
  process.env.NODE_ENV === "production" &&
  process.env.GATSBY_ENV === "production"

// const isProd = true

const encode = (data) => {
  return Object.keys(data)
      .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
}

const defaultValues = {
  email: "",
  spamfilter: null, // honeypot
}

const schema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .email("valid email is required")
    .required("email is required"),
})

const ContactForm = () => {
  const [formMsg, setFormMsg] = useState()

  const {
    handleSubmit,
    register,
    reset,
    setError,
    formState: { isSubmitting, errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  })

  const onSubmit = async (values) => {
    if (!values.spamfilter && isProd) {
      try {
        fetch("https://sheet.best/api/sheets/2f3c602d-2358-4aa7-ad40-55c247158a01", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            "email":values.email
          }),
        })
            .then(() => {
              reset(defaultValues)
              setFormMsg("Thank you for joining!")
            })
            .catch((error) => console.log(error))
      } catch (err) {
        if (err.response && err.response.data) {
          const invalidEmail =
            err.response.data.errors[0].errorType === "INVALID_EMAIL"

          setError("email", {
            type: "manual",
            message: invalidEmail
              ? "Email is invalid, please try again."
              : "Sorry an unknown error occured, please try again.",
            shouldFocus: true,
          })
        }
        else {
          setError("email", {
            type: "manual",
            message: "Sorry an unknown error occured, please try again.",
            shouldFocus: true,
          })
        }
      }
    } else {
      reset(defaultValues)
      setError("email", {
        type: "manual",
        message: "Not Submitted, Not Production",
        shouldFocus: true,
      })
    }
  }
  return (
    <StyledContactForm
      id="ContactForm"
      name="newsletter-signups"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      {formMsg ? (
          <FormMsg>{formMsg}</FormMsg>
      ) : (
          <>
          <Input
            errors={errors}
            className="spamfilter"
            id="spamfilter"
            type="text"
            name="spamfilter"
            placeholder="spamfilter"
            register={register}
          />
          <One
            className="email"
            type="email"
            name="email"
            placeholder="Enter your email address"
            errors={errors}
            register={register}
            isSubmitting={isSubmitting}
            text="Sign up"
            required
          />
        </>
        )}
    </StyledContactForm>
  )
}

const StyledContactForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .spamfilter-wrap {
    position: absolute;
    z-index: -9999;
    left: 0;
    bottom: 0;
    visibility: hidden;
  }
`

const FormMsg = styled.p`
  text-align: center;
  padding: var(--sp-16);
  max-width: 540px;
`

export default ContactForm
