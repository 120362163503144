import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { ErrorMessage } from "@hookform/error-message"

import { device } from "utils"

/**
 * @description Error Component
 * @typedef {{
 * name: String
 * errors: {}
 *
 * Props
 * @type React.FunctionComponent<Props>
 *
 * @example
 * <Error name="test" errors={errors} />
 */

const Error = ({ errors, name }) => (
  <ErrorMessage
    className="error"
    errors={errors}
    name={name}
    render={({ message }) => <StyledError>{message}</StyledError>}
  />
)

const StyledError = styled.p`
  font-size: 12px;
  line-height: 12px;
  /* margin-top: 4px;
  margin-left: var(--sp-12); */
  color: var(--red);

  position: absolute;
  bottom: -14px;
  left: var(--sp-16);

  @media ${device.laptop} {
    bottom: -16px;
  }
`

Error.propTypes = {
  name: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired,
}

export default Error
