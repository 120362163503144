import React from "react"
import styled from "styled-components"

import { VSection, Cta } from "components/common"

import ContactForm from "components/forms/ContactForm"

const BottomNewsletterCta = ({ hat, title, media, ctas }) => {
  const renderForm = () => {
    return <ContactForm />
  }

  return (
    <StyledBottomNewsletterCta>
      <VSection
        id="contact"
        hat={hat}
        title={title}
        titleAs="h2"
        media={media}
        custom={renderForm}
        cta={() => <Cta ctas={ctas} className="socal-cta" />}
      />
    </StyledBottomNewsletterCta>
  )
}

const StyledBottomNewsletterCta = styled.div`
  --section-padding-bottom: 0;
  --section-padding-top: var(--sp-160);
  --section-mobile-padding-top: var(--sp-80);
  --section-mobile-padding-bottom: 0;

  margin-bottom: -120px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;

  #contact {
    .v_tgContainer {
      position: relative;

      --tg-text-align: center;
      --tg-max-width: 750px;
      --section-align: center;
    }

    .v_customContainer {
      --section-order: 2;
      --section-mobile-order: 2;
      margin-top: var(--sp-40);
    }
  }

  .inputContainer {
    button {
      height: 51px;
    }
  }

  .v_ctaContainer {
    margin-top: var(--sp-40);
    --cta-grid-gap: var(--sp-16);

    .socal-cta {
      display: flex;
      justify-content: center;
      button {
        border-radius: 50%;
        min-width: 48px;
        width: 48px;
        height: 48px;

        .front {
          border-radius: 50%;
          height: 100%;
          padding: 0;
          width: 100%;
        }
      }
    }
  }
`

export default BottomNewsletterCta
