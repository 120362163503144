import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { ErrorMessage } from "@hookform/error-message"

// https://codesandbox.io/s/react-hook-form-controller-079xx?file=/src/index.js:833-1061

/**
 * @description TextArea Component
 * @typedef {{
 * name: String
 * type: String
 * rows: String
 * className: String
 * placeholder: String
 * required: Boolean
 * register: Function
 * errors: {}
 *
 * Props
 * @type React.FunctionComponent<Props>
 *
 * @example
 * <TextArea name="test" />
 */

const TextArea = ({
  name,
  register,
  className,
  type,
  rows,
  placeholder,
  required,
  errors,
}) => {
  return (
    <TextAreaWrap>
      <StyledTextarea
        name={name}
        type={type}
        className={className ? `${className} textarea` : "textarea"}
        placeholder={placeholder}
        rows={rows}
        {...register(name, {
          required,
        })}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <Error>{message}</Error>}
      />
    </TextAreaWrap>
  )
}

const TextAreaWrap = styled.div``
const StyledTextarea = styled.textarea`
  font-size: 20px;
  border: 1px solid #e0e0e0;
  width: 100%;
  padding: var(--sp-16);
  border-radius: 3px;
  font-family: var(--primary-font-family);
  font-weight: normal;
  &:focus {
    outline: none;
    border: 1px solid #f17176;
  }

  &::placeholder {
    color: #5f5f5f;
  }
`

const Error = styled.p`
  font-size: 12px;
  color: red;
  margin-top: 2px;
`

TextArea.defaultProps = {
  name: "",
  rows: "4",
  className: "",
  type: "text",
  placeholder: "placeholder",
  required: false,
}

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  rows: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  className: PropTypes.string,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
}

export default TextArea
